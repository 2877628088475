import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { curry, init, last } from 'ramda';

// components
import Code from '../Code';
import Markdown from '../Markdown';
import Icon from '../Icon';

// local
import styles from './Card.module.css';

const splitPath = path => {
  const split = path.split('/');
  return [init(split).join('/'), last(split)];
};

// const importStatement = curry((mod, path) => {
//   const [parent, name] = splitPath(path);
//   const modulePath = [mod, parent].join('/');
//   return `import { ${ name } } from '${ modulePath }';`;
// });

const cjsStatement = curry((mod, path) => {
  const [parent, name] = splitPath(path);
  const modulePath = [mod, parent]
    .join('/')
    .replace(/^\/|\/$/g, '');
  return `const { ${ name } } = require('${ modulePath }');`;
});

export default class Card extends PureComponent {
  
  static propTypes = {
    collapse: PropTypes.bool,
    doc: PropTypes.shape({
      path: PropTypes.string,
    }),
    id: PropTypes.string,
    link: PropTypes.string,
    onLinkClick: PropTypes.func,
    onQuery: PropTypes.func,
  }

  static defaultProps = {
    onLinkClick: () => {},
    collapse: false,
    doc: {},
    onQuery: () => {},
  }
  
  render() {
    const { doc, collapse, onLinkClick, link } = this.props;
    
    const examples = (doc.examples.length ? doc.examples : ['']);
    
    return (
      <div className={ `${ styles.card } ${ styles[doc.kind] }` }>
        <div className={ styles.top }>
        
          <div className={ styles.header }>
          
            <div className={ styles.left }>
              <h2 className={ styles.path }>
                <Link onClick={ onLinkClick } to={ link }>{
                  doc.path.split('/').map((word, i) => (
                    <Fragment key={ i }>
                      { i ? (<span key={ 0 } className={ styles.slash }>{ '/' }</span>) : null }
                      <span key={ 1 }>{ word }</span>
                    </Fragment>
                  ))
                }</Link>
              </h2>
            
              <div
                className={ styles.deprecated }
                style={{ display: (doc.deprecated ? null : 'none') }}
              >
                <Icon
                  className={ styles.icon }
                  title={ 'Deprecated' }
                  type={ 'warning' } />
                { ' Deprecated' }
              </div>
              
            </div>
              
            <a
              className={ styles.sourceLink }
              href={ doc.url }
              target={ '_new' }
              title={ 'View Source' }
            >
              <Icon type={ 'code' } />
            </a>
          </div>
          
          <div style={{ display: collapse ? 'none' : 'block' }}>
            { doc.sig && <Code className={ styles.sig }>{ doc.sig }</Code> }
            {
              doc.description && (
                <Markdown
                  className={ styles.description }
                  inline={ false }>{ doc.description }</Markdown>
              )
            }
          </div>
          
        </div>
        {
          examples.length && !collapse
            ? (
              <div style={{ lineHeight: 0 }}>
                {
                  examples.map((example, i) => (
                    <Code
                      lang={ 'javascript' }
                      className={ styles.example }
                      key={ i }
                      inline={ false }
                    >{
                        [
                          cjsStatement('funk-lib', doc.path),
                          (example ? `\n${ example }` : ''),
                        ].join('\n')
                      }</Code>
                  ))
                }
              </div>
            )
            : null
        }
      </div>
    );

    
  }
  
}

