// module
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// local
import styles from './Markdown.module.css';
import { markdown } from './lib';

const md = markdown();

export default class Markdown extends PureComponent {
  
  static propTypes = {
    // the code to display
    children: PropTypes.string,
    className: PropTypes.string,
  }
  
  static defaultProps = {
    children: '',
  }
  
  state = {}

  render() {
    const { children } = this.props;
    const { className } = this.props;
    const computedClassNames = classnames(className, styles.markdown);

    return (
      <div
        className={ computedClassNames }
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: md.render(children) }} />
    );
  }

}
