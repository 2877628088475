// modules
import React, { PureComponent, Fragment } from 'react';
import {
  filter,
  fromPairs,
  map,
  pipe,
  prop,
  replace,
  sortBy,
  split,
} from 'ramda';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
// import { navigate } from '@reach/router';

// local
import logo from '../images/funk-lib.jpg';
import Card from '../components/Card';
import Markdown from '../components/Markdown';
import Layout from '../components/Layout';
import styles from './index.module.css';
import '../global.module.css';


const parseQueryString = pipe(
  replace(/^\?/, ''),
  split('&'),
  map(split('=')),
  filter(([key]) => key),
  fromPairs,
);

// const serializeQuery = pipe(
//   toPairs,
//   chain(([key, val]) => {
//     if (val === '' || val === undefined)  return [];
//     return [join('=', [key, val])];
//   }),
//   join('&'),
// );


const docHash = doc => doc.path.replace(/\//g, '.');

const onLocationChange = fn => {
  const { pushState } = history;
  
  history.pushState = state => {
    const prev = window.location.href;
    setTimeout(() => {
      if (window.location.href === prev) return;
      fn(window.location);
    }, 0);
    return pushState(state);
  };
  
  // unsub
  return () => {
    history.pushState = pushState;
  };
};


const siteMeta = siteMetadata => [
  {
    name: 'description',
    content: siteMetadata.description,
  },
  {
    name: 'keywords',
    content: siteMetadata.keywords.join(', '),
  },
  {
    property: 'og:title',
    content: siteMetadata.title,
  },
  {
    property: 'og:description',
    content: siteMetadata.description,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    name: 'twitter:card',
    content: 'summary',
  },
  {
    name: 'twitter:creator',
    content: siteMetadata.twitterHandle,
  },
  {
    name: 'twitter:title',
    content: siteMetadata.title,
  },
  {
    name: 'twitter:description',
    content: siteMetadata.description,
  },
];


export default class Index extends PureComponent {
  
  static propTypes = {
    data: PropTypes.shape({
      
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
      hash: PropTypes.string.isRequired,
    }),
  }
  static defaultProps = {
    data: {},
    location: {},
  }
  
  state = {
    query: '',
    showMenu: false,
    override: false,
  }
  
  static getDerivedStateFromProps = (nextProps, prevState) => {
    const { query: prevQuery, override } = prevState;
    const { q: nextQuery = '' } = parseQueryString(nextProps.location.search);
    
    if (override) return null;
    if (nextQuery === prevQuery) return null;
    return { query: nextQuery, override: false };
  }
  
  componentDidMount() {
    const { hash } = window.location;
    
    // scroll to active path
    if (hash) {
      const escaped = hash.replace(/\./g, '\\.');
      const id = document.querySelector(escaped);
      if (id) {
        window.scrollTo(0, id.offsetTop);
      } else {
        // eslint-disable-next-line no-console
        console.error('invalid hash: ', escaped);
      }
    }
    
    this.historyUnsub = onLocationChange(location => {
      const pathname = window
        .location
        .hash
        .replace(/^#/, '')
        .split('.')
        .join('/');
      
      // eslint-disable-next-line no-unused-expressions
      window.ga && window.ga('send', 'pageview', pathname);
    });
  }
  
  componentWillUnmount() {
    this.historyUnsub();
  }
    
  handleQuery = query => this.setState({ query, override: true });
  handleToggleMenu = _ => this.setState({
    showMenu: !this.state.showMenu,
  });
  
  handleLinkClick = _ => this.setState({ showMenu: false });

  render() {
    const { data } = this.props;
    const { showMenu, query } = this.state;
    
    const docs = sortBy(prop('path'), data.site.siteMetadata.jsdocs);
    
    return (
      <Layout
        className={ styles.index }
        docs={ docs }
        title={ data.site.siteMetadata.title }
        logo={ logo }
        // eslint-disable-next-line react/jsx-no-bind
        getLink={ doc => `/#${ docHash(doc) }` }
        onLinkClick={ this.handleLinkClick }
        onQuery={ this.handleQuery }
        query={ decodeURIComponent(query) }
        menuActive={ showMenu }
        onToggleMenu={ this.handleToggleMenu }
        version={ data.site.siteMetadata.version }
      >
        <Helmet
          title={ data.site.siteMetadata.title }
          meta={ siteMeta(data.site.siteMetadata) }
        />
        <Markdown className={ styles.readme }>
          { data.site.siteMetadata.readme }
        </Markdown>
        {
          docs
            .filter(_ => _)
            .map(doc => (
              <Fragment key={ doc.path }>
                <div
                  className={ styles.id }
                  id={ docHash(doc) }
                >
                  { docHash(doc) }
                </div>
                {
                  (doc.kind === 'module')
                    ? <hr
                      style={{
                        borderTop: '1px solid rgba(0, 0, 0, 0.2)',
                        marginBottom: '25px',
                      }} /> : null
                }
                <Card
                  link={ `/#${ docHash(doc) }` }
                  // collapse={ (doc.kind === 'module') }
                  onQuery={ this.handleQuery }
                  doc={ doc } />
              </Fragment>
            ))
        }</Layout>
    );
  }
}

export const query = graphql`{
  site {
    siteMetadata {
      description
      title
      keywords
      version
      twitterHandle
      readme
      jsdocs {
        path
        sig
        examples
        kind
        description
        deprecated
        url
      }
    }
  }
}`;
