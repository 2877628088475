// modules
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// local
// import styles from './Icon.module.css';

export default class Icon extends PureComponent {
  
  static propTypes = {
    className: PropTypes.string,
    hidden: PropTypes.bool,
    onClick: PropTypes.func,
    style: PropTypes.shape({}),
    title: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
  };

  render() {
    const { className, type, hidden, onClick, style, title } = this.props;
    if (hidden) return null;
    
    return (
      <span
        style={ style }
        onClick={ onClick }
        title={ title }
        className={ `material-icons ${ className }` }>{ type }</span>
    );
  }
}

