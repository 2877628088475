// modules
import React, { PureComponent } from 'react';
// import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// import { navigate } from '@reach/router';

// local
import NavList from '../NavList';
import MenuBar from '../MenuBar';
import styles from './Layout.module.css';


export default class Layout extends PureComponent {
  
  static propTypes = {
    children: PropTypes.node,
    docs: PropTypes.arrayOf(PropTypes.shape({})),
    getLink: PropTypes.func,
    logo: PropTypes.string,
    menuActive: PropTypes.bool,
    onLinkClick: PropTypes.func,
    onQuery: PropTypes.func,
    onToggleMenu: PropTypes.func,
    query: PropTypes.string,
    title: PropTypes.string,
    version: PropTypes.string,
  }
  static defaultProps = {
    docs: [],
    getLink: () => {},
    menuActive: false,
    onLinkClick: () => {},
    onQuery: () => {},
    onToggleMenu: () => {},
    query: '',
    title: '',
    version: '0.0.0',
  }
  
  render() {
    const {
      children,
      docs,
      getLink,
      menuActive,
      onLinkClick,
      onQuery,
      onToggleMenu,
      query,
      logo,
      title,
      version,
    } = this.props;
    
    // todo: don't hardcode this
    const versionLink = `https://github.com/colbydauph/funk-lib/releases/tag/${ version }`;
    
    return (
      <div className={ styles.index }>
        <div className={ styles.content }>
          { children }
        </div>
        <NavList
          className={ classnames(styles.navList, { [styles.active]: menuActive }) }
          query={ query }
          onQuery={ onQuery }
          onLinkClick={ onLinkClick }
          // eslint-disable-next-line react/jsx-no-bind
          getLink={ getLink }
          jsdocs={ docs } />
        <MenuBar
          onToggleMenu={ onToggleMenu }
          menuActive={ menuActive }
          logo={ logo }
          title={ title }
          className={ styles.menuBar }
          version={ (
            <a
              className={ styles.version }
              href={ versionLink }
              title={ `View Release v${ version }` }
              target={ '_new' }>{ `v${ version }` }</a>
          ) } />
      </div>
    );
  }
}
