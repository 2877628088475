// modules
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

// aliased
import Icon from '../Icon';

// local
import styles from './MenuBar.module.css';

import github from '../../images/github.svg';


export default class MenuBar extends PureComponent {
  
  static propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string,
    menuActive: PropTypes.bool,
    onToggleMenu: PropTypes.func,
    title: PropTypes.string,
    version: PropTypes.node,
  }
  
  static defaultProps = {
    version: '0.0.0',
    title: '',
    menuActive: false,
    onToggleMenu: () => {},
  }
  
  render() {
    const { className, menuActive, onToggleMenu, title, logo, version } = this.props;
    
    return (
      <div className={ `${ styles.menuBar } ${ className }` }>
        
        <h2 className={ styles.left }>
          <Link className={ styles.home } to={ '#' } title={ 'Home' }>
            <img className={ styles.logo } src={ logo } />
            { title }
          </Link>
          { version }
        </h2>
        
        <div className={ styles.right }>
          <a className={ styles.try } href={ 'https://npm.runkit.com/funk-lib' } target={ '_new' } title={ 'Try funk-lib' }>
            { 'Try' }
          </a>
          <a href={ 'https://github.com/colbydauph/funk-lib' } target={ '_new' } title={ 'GitHub Repo' }>
            <img className={ styles.github } src={ github } />
          </a>
          <Icon
            className={ styles.menuIcon }
            onClick={ onToggleMenu }
            type={ (menuActive ? 'close' : 'menu') } />
        </div>
      </div>
    );
    
  }
  
}
